let eventGuid = 0;
var today = new Date();
var y = today.getFullYear();

export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'Mo Bui - Travel',
    start: new Date(y, 5, 2),
    end: new Date(y, 5, 30),
    allDay: true,
    className: 'event event-group-0',
  },
];
for (let i = 0; i <= 10; i++) {
  INITIAL_EVENTS.push({
    id: createEventId(),
    title: `Event ${i + 1} - Travel`,
    start: new Date(y, 5, 13),
    allDay: true,
    className: `event event-group-${i}`,
  });
}

export function createEventId() {
  return String(eventGuid++);
}
